import { Lycee } from "../../types";

export enum LYCEES_ACTIONS {
  LYCEES_LIST = "LYCEES_LIST",
  LYCEES_ERROR = "LYCEES_ERROR",
  LYCEES_MODAL = "LYCEES_MODAL",
  LYCEES_LOADING = "LYCEES_LOADING",
  LYCEES_MUTATION_LOADING = "LYCEES_MUTATION_LOADING",
}

export interface LyceesState {
  lycees: Lycee[];
  showModal: boolean;
  isLoading: boolean;
  isMutationLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: LyceesState = {
  lycees: [],
  showModal: false,
  isLoading: false,
  isMutationLoading: false,
  error: null,
};

export interface LyceesActionType {
  type: keyof typeof LYCEES_ACTIONS;
  payload: any;
}

const reducer = (
  state: LyceesState = initialState,
  action: LyceesActionType
): LyceesState => {
  switch (action.type) {
    case LYCEES_ACTIONS.LYCEES_LIST:
      return {
        ...state,
        lycees: action.payload,
        isLoading: false,
      };

    case LYCEES_ACTIONS.LYCEES_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case LYCEES_ACTIONS.LYCEES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case LYCEES_ACTIONS.LYCEES_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };

    case LYCEES_ACTIONS.LYCEES_MUTATION_LOADING:
      return {
        ...state,
        isMutationLoading: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
