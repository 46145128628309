import { Profession } from "../../types";

export enum PROFESSIONS_ACTIONS {
  PROFESSIONS_LIST = "PROFESSIONS_LIST",
  PROFESSIONS_ERROR = "PROFESSIONS_ERROR",
  PROFESSIONS_MODAL = "PROFESSIONS_MODAL",
  PROFESSIONS_LOADING = "PROFESSIONS_LOADING",
  PROFESSIONS_MUTATION_LOADING = "PROFESSIONS_MUTATION_LOADING",
}

export interface ProfessionsState {
  professions: Profession[];
  showModal: boolean;
  isLoading: boolean;
  isMutationLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: ProfessionsState = {
  professions: [],
  showModal: false,
  isLoading: false,
  isMutationLoading: false,
  error: null,
};

export interface ProfessionsActionType {
  type: keyof typeof PROFESSIONS_ACTIONS;
  payload: any;
}

const reducer = (
  state: ProfessionsState = initialState,
  action: ProfessionsActionType
): ProfessionsState => {
  switch (action.type) {
    case PROFESSIONS_ACTIONS.PROFESSIONS_LIST:
      return {
        ...state,
        professions: action.payload,
        isLoading: false,
      };

    case PROFESSIONS_ACTIONS.PROFESSIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case PROFESSIONS_ACTIONS.PROFESSIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case PROFESSIONS_ACTIONS.PROFESSIONS_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };

    case PROFESSIONS_ACTIONS.PROFESSIONS_MUTATION_LOADING:
      return {
        ...state,
        isMutationLoading: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
