import { Video } from "../../types";

export enum VIDEOS_ACTIONS {
  VIDEOS_LIST = "VIDEOS_LIST",
  VIDEOS_ERROR = "VIDEOS_ERROR",
  VIDEOS_MODAL = "VIDEOS_MODAL",
  VIDEOS_LOADING = "VIDEOS_LOADING",
  VIDEOS_MUTATION_LOADING = "VIDEOS_MUTATION_LOADING",
}

export interface VideosState {
  videos: Video[];
  showModal: boolean;
  isLoading: boolean;
  isMutationLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: VideosState = {
  videos: [],
  showModal: false,
  isLoading: false,
  isMutationLoading: false,
  error: null,
};

export interface VideosActionType {
  type: keyof typeof VIDEOS_ACTIONS;
  payload: any;
}

const reducer = (
  state: VideosState = initialState,
  action: VideosActionType
): VideosState => {
  switch (action.type) {
    case VIDEOS_ACTIONS.VIDEOS_LIST:
      return {
        ...state,
        videos: action.payload,
        isLoading: false,
      };

    case VIDEOS_ACTIONS.VIDEOS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case VIDEOS_ACTIONS.VIDEOS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case VIDEOS_ACTIONS.VIDEOS_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };

    case VIDEOS_ACTIONS.VIDEOS_MUTATION_LOADING:
      return {
        ...state,
        isMutationLoading: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
