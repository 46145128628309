import { ProfessionCategory } from "../../types";

export enum PROFESSIONS_CATEGORIES_ACTIONS {
  PROFESSIONS_CATEGORIES_LIST = "PROFESSIONS_CATEGORIES_LIST",
  PROFESSIONS_CATEGORIES_ERROR = "PROFESSIONS_CATEGORIES_ERROR",
  PROFESSIONS_CATEGORIES_MODAL = "PROFESSIONS_CATEGORIES_MODAL",
  PROFESSIONS_CATEGORIES_LOADING = "PROFESSIONS_CATEGORIES_LOADING",
  PROFESSIONS_CATEGORIES_MUTATION_LOADING = "PROFESSIONS_CATEGORIES_MUTATION_LOADING",
}

export interface ProfessionCategoriesState {
  professionCategories: ProfessionCategory[];
  showModal: boolean;
  isLoading: boolean;
  isMutationLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: ProfessionCategoriesState = {
  professionCategories: [],
  showModal: false,
  isLoading: false,
  isMutationLoading: false,
  error: null,
};

export interface ProfessionCategoriesActionType {
  type: keyof typeof PROFESSIONS_CATEGORIES_ACTIONS;
  payload: any;
}

const reducer = (
  state: ProfessionCategoriesState = initialState,
  action: ProfessionCategoriesActionType
): ProfessionCategoriesState => {
  switch (action.type) {
    case PROFESSIONS_CATEGORIES_ACTIONS.PROFESSIONS_CATEGORIES_LIST:
      return {
        ...state,
        professionCategories: action.payload,
        isLoading: false,
      };

    case PROFESSIONS_CATEGORIES_ACTIONS.PROFESSIONS_CATEGORIES_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case PROFESSIONS_CATEGORIES_ACTIONS.PROFESSIONS_CATEGORIES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case PROFESSIONS_CATEGORIES_ACTIONS.PROFESSIONS_CATEGORIES_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };

    case PROFESSIONS_CATEGORIES_ACTIONS.PROFESSIONS_CATEGORIES_MUTATION_LOADING:
      return {
        ...state,
        isMutationLoading: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
