import { Admin } from "../../types";

export enum ADMINS_ACTIONS {
  ADMINS_LOADED_SUCCESS = "ADMINS_LOADED_SUCCESS",
  ADMINS_LOADED_FAILED = "ADMINS_LOADED_FAILED",
  ADMINS_LOADING = "ADMINS_LOADING",
  ADMINS_MUTATION_LOADING = "ADMINS_MUTATION_LOADING",
  ADMINS_MODAL = "ADMINS_MODAL",
}

export interface Adminstate {
  admins: Admin[];
  showModal: boolean;
  isLoading: boolean;
  isMutationLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: Adminstate = {
  admins: [],
  isLoading: false,
  showModal: false,
  isMutationLoading: false,
  error: null,
};

export interface AdminActionType {
  type: keyof typeof ADMINS_ACTIONS;
  payload: any;
}

const JobReducer = (
  state: Adminstate = initialState,
  action: AdminActionType
): Adminstate => {
  switch (action.type) {
    case ADMINS_ACTIONS.ADMINS_LOADED_SUCCESS:
      return {
        ...state,
        admins: action.payload,
        isLoading: false,
      };

    case ADMINS_ACTIONS.ADMINS_LOADED_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ADMINS_ACTIONS.ADMINS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ADMINS_ACTIONS.ADMINS_MUTATION_LOADING:
      return {
        ...state,
        isMutationLoading: action.payload,
      };

    case ADMINS_ACTIONS.ADMINS_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default JobReducer;
