import { BlogArticle } from "../../types";

export enum BLOG_ARTICLE_ACTIONS {
  BLOG_ARTICLE_LIST = "BLOG_ARTICLE_LIST",
  BLOG_ARTICLE_ERROR = "BLOG_ARTICLE_ERROR",
  BLOG_ARTICLE_MODAL = "BLOG_ARTICLE_MODAL",
  BLOG_ARTICLE_LOADING = "BLOG_ARTICLE_LOADING",
  BLOG_ARTICLE_MUTATION_LOADING = "BLOG_ARTICLE_MUTATION_LOADING",
}

export interface BlogArticlesState {
  articles: BlogArticle[];
  showModal: boolean;
  isLoading: boolean;
  isMutationLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: BlogArticlesState = {
  articles: [],
  showModal: false,
  isLoading: false,
  isMutationLoading: false,
  error: null,
};

export interface BlogArticlesActionType {
  type: keyof typeof BLOG_ARTICLE_ACTIONS;
  payload: any;
}

const reducer = (
  state: BlogArticlesState = initialState,
  action: BlogArticlesActionType
): BlogArticlesState => {
  switch (action.type) {
    case BLOG_ARTICLE_ACTIONS.BLOG_ARTICLE_LIST:
      return {
        ...state,
        articles: action.payload,
        isLoading: false,
      };

    case BLOG_ARTICLE_ACTIONS.BLOG_ARTICLE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case BLOG_ARTICLE_ACTIONS.BLOG_ARTICLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case BLOG_ARTICLE_ACTIONS.BLOG_ARTICLE_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };

    case BLOG_ARTICLE_ACTIONS.BLOG_ARTICLE_MUTATION_LOADING:
      return {
        ...state,
        isMutationLoading: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
