import { User } from "../../types";
import { AUTH_ACTIONS, AuthActionType } from "./reducers";

export const loginUser = ({user, token}: {user: User, token: string}): AuthActionType => ({
  type: AUTH_ACTIONS.LOGIN_SUCCESS,
  payload: {user, token},
});

export const loginFailed = (error: any): AuthActionType => ({
  type: AUTH_ACTIONS.LOGIN_FAILED,
  payload: { error },
});

export const setAuthError = (error: any): AuthActionType => ({
  type: AUTH_ACTIONS.SET_ERROR,
  payload: error,
})

export const logoutUser = (): AuthActionType => ({
  type: AUTH_ACTIONS.LOGOUT,
  payload: {},
});

export const signupUser = (
  fullname: string,
  email: string,
  password: string
): AuthActionType => ({
  type: AUTH_ACTIONS.LOGIN_FAILED,
  payload: { fullname, email, password },
});

export const resetAuth = (): AuthActionType => ({
  type: AUTH_ACTIONS.LOGOUT,
  payload: {},
});
