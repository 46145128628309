import { Admin } from "../../types";
export enum AUTH_ACTIONS {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILED = "LOGIN_FAILED",
  LOGOUT = "LOGOUT",
  LOADING = "LOADING",
  SET_ERROR = "SET_ERROR",
}

export interface AuthState {
  user: Admin | null;
  token: string | null;
  isLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  isLoading: false,
  error: null,
};

export interface AuthActionType {
  type: keyof typeof AUTH_ACTIONS;
  payload: any;
}

const authReducer = (
  state: AuthState = initialState,
  action: AuthActionType
): AuthState => {
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLoading: false,
      };

    case AUTH_ACTIONS.LOGIN_FAILED:
      return {
        ...state,
        user: null,
        token: null,
        isLoading: false,
        error: action.payload.error,
      };

    case AUTH_ACTIONS.LOADING:
      return {
        ...initialState,
        isLoading: true,
      };

    case AUTH_ACTIONS.SET_ERROR:
      return {
        ...initialState,
        error: action.payload,
      };

    case AUTH_ACTIONS.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return {
        ...state,
      };
  }
};

export default authReducer;
